import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMeta from "vue-meta"


Vue.config.productionTip = false
Vue.prototype.$services = [
  {
    title: "Map",
    path: "/map"
  },
  {
    title: "Radar",
    path: "/radar"
  },
  {
    title: "State Sync",
    path: "/state-sync"
  },
  {
    title: "IBC Denoms",
    path: "/ibc-denoms"
  },
  {
    title: "Delegations",
    path: "/delegations"
  },
  {
    title: "Countdown",
    path: "/countdown",
    always: true
  },
  {
    title: "Price Feeder",
    path: "/price-feeder",
  }
]

Vue.prototype.$mainnets = []
Vue.prototype.$testnets = []

const chains = require("../public/data/chains.json")
const testnets = require("../public/data/testnets.json")
const mainnets = require("../public/data/mainnets.json")


chains.forEach(chain => {
  let network = mainnets[chain.name]
  if (network !== undefined) {
    
    network.main = true
    network.text = `${chain.name} - ${network.chain_id}`
    if (chain.excludes === undefined) {
      chain.excludes = ["/radar", "/ibc-denoms"]
    }
    if (chain.name != "kujira") {
      chain.excludes.push("/price-feeder")
    }
    Vue.prototype.$mainnets.push(
      Object.assign({}, chain, network)
    )
  }

  let networks = testnets[chain.name]
  if (networks !== undefined) {
    for (let network of networks) {
      network.text = `${chain.name} - ${network.chain_id}`
      Vue.prototype.$testnets.push(
        Object.assign({}, chain, network)
      )
    }
  }



  

  
 
  
  // let mainnet = registry[chain.name]
  // if (mainnet === undefined) {
  //   mainnet = {
  //     name: "<placeholder>"
  //   }
  // }
  // mainnet["excludes"] = ["/ibc-denoms", "/state-sync", "/radar"]
  // chain.networks = [mainnet]
})

// let allProviders = {
//   "kujira": {
//     "harpoon-4": [
//       {
//         name: "mintthemoon",
//         website: "https://mintthemoon.xyz",
//         rpcURL: "https://rpc-kujira.starsquid.io"
//       }
//     ]
//   }
// }

// Vue.prototype.$chains.forEach(chain => {
//   chain.networks.forEach(network => {
//     if (allProviders[chain.name] !== undefined) {
//       let providers = allProviders[chain.name][network.name]
//       if (providers !== undefined) {
//         network["providers"] = providers
//       }
//     }
//   })
// });

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueMeta)
