<template>
  <div class="py-10">
    <h1 class="text-h3 pb-10 font-weight-light">Supported Chains</h1>
    
    <v-row  justify="start">
      <v-col
        cols="6"
        lg="3"
        md="4"
        sm="6"
        v-for="(chain, index) in chains"
        :key="index"
      >
        <v-hover
          v-slot="{ hover }"
          @blur="chain.reveal = false"
        >
          <v-card
            outlined
            :dark="chain.dark ? true : false"
          >
            <v-sheet
            :color="chain.colors ? chain.colors[chain.colors.length-1] : null"
            >
              <v-img
                
                :gradient="chain.colors !== undefined ? chain.colors.join(',') : null"
              >
                <v-container
                  fill-height
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="pa-7"
                    >
                      <v-img
                        contain
                        :aspect-ratio="5/3"
                        :src="chain.logo !== undefined ? `/img/logos/${chain.symbol}.${chain.logo}` : `/img/logos/${chain.symbol}.svg`"
                      >
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </v-sheet>
            
            <v-fade-transition>
              <v-overlay
                v-if="hover"
                style="backdrop-filter: blur(5px);"
                absolute
                :opacity="chain.dark ? 0.4 : 0.3"
                :color="chain.dark ? '#000' : '#fff'"
              >
                <v-btn
                  color="primary"
                  large
                  :href="chain.stakingURL ? chain.stakingURL : ''"
                  target="_blank"
                >
                  Stake ${{ chain.symbol }}
                </v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {

  name: "Chains",

  data: () => ({
    chains: []
  }),

  mounted() {
    let chains = []

    this.$mainnets.filter(chain => chain.stakingURL).forEach((chain) => {
      chains.push({...chain})
    })
    // .forEach((item) => {
    //   let chain = {...item}
    //   let mainnet = chain.networks.filter(net => net.main === true)[0]
    //   chains.push(Object.assign({}, mainnet, chain))
    // })

    let ordered = chains
      .sort((a, b) => (a.name).localeCompare(b.name))

    this.chains = ordered
  }
};
</script>

<style>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>