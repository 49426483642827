import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

// const mainnets = [
//   ["kujira", "kaiyo-1"],
//   ["bitsong", "bitsong-2b"],
//   ["desmos", "desmos-mainnet"],
//   ["lum", "lum-network-1"],
//   ["persistence", "core-1"],
//   ["shentu", "shentu-2.2"],
//   ["konstellation", "darchub"]
// ]

let routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/imprint',
    name: 'Imprint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../components/Imprint.vue")
  },
  {
    path: "/state-sync",
    props: {title: "State-Sync", path: "/state-sync"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/state-sync/:name/:id",
    props: true,
    component: () => import("../components/StateSync.vue")
  },
  {
    path: "/radar",
    props: {title: "Radar", path: "/radar"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/radar/:name/:id",
    props: true,
    component: () => import("../components/Radar.vue")
  },
  {
    path: "/ibc-denoms",
    props: {title: "IBC Denoms", path: "/ibc-denoms"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/ibc-denoms/:name/:id",
    props: true,
    component: () => import("../components/IBCDenoms.vue")
  },
  {
    path: "/map",
    props: {title: "Node Map", path: "/map"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/map/:name/:id",
    props: true,
    component: () => import("../components/Map.vue")
  },
  {
    path: "/delegations",
    props: {title: "Delegations", path: "/delegations"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/delegations/:name/:id",
    props: true,
    component: () => import("../components/Delegations.vue")
  },
  {
    path: "/price-feeder",
    props: {title: "Price Feeder", path: "/price-feeder"},
    component: () => import("../components/ChainSelect.vue")
  },
  {
    path: "/price-feeder/:name/:id",
    props: true,
    component: () => import("../components/PriceFeeder.vue")
  },
  {
    path: "/countdown",
    component: () => import("../components/Countdown.vue")
  },
  
  {
    path: "*",
    component: () => import("../views/PageNotFound.vue")
  }
]

// const testnets = require("../public/data/testnets.json")
const mainnets = require("../../public/data/mainnets.json")

for (const [name, chain] of Object.entries(mainnets)) {
  for (let path of ["state-sync", "radar", "ibc-denoms", "map", "delegations", "price-feeder"]) {
    routes.push({
      path: `/${path}/${name}`,
      redirect: `/${path}/${name}/${chain.chain_id}`
    })
  }
}

  // let name = pair[0]
  // let id = pair[1]
  // for (let path of ["state-sync", "radar", "ibc-denoms", "map"]) {
  //   routes.push({
  //     path: ["", path, name].join("/"),
  //     redirect: ["", path, name, id].join("/")
  //   })
  // }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
