<template>
  <v-container 
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="8"
      >
        <span class="text-md-h1 text-h2">
          Stake your crypto and earn interest
        </span>
        <div class="ma-10">
        </div>
        <div>
          <v-btn
            large
            color=primary
            @click="$vuetify.goTo(parentRefs.chains, options)"
          >
            Stake with us
          </v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
 
</template>

<script>
  export default {
    name: 'Hero',

    props: ["parentRefs"],

    data: () => ({
      options: {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic"
      }
    }),
    methods: {
      test: function() {
        // @click="$vuetify.goTo(this.$parent.$refs.chains, options)"
        console.log(this.parentRefs)
      }
    },
  }
</script>
