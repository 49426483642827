<template>
  <div style="margin-top: -64px;">
    <div style="background-color: #050019;">
    <v-parallax
      style="padding-top: 64px;"
      src="bg-hero.jpg"
      height="664"
    >
      <Hero :parentRefs="$refs"/>
    </v-parallax>
    </div>

    <v-container>
      <Benefits/>
      <v-divider></v-divider>
      <Chains ref="chains"/>
    </v-container>
  </div>
</template>


<script>
import Benefits from '@/components/Benefits.vue';
import Hero from '@/components/Hero.vue';
import Chains from '@/components/Chains.vue';

export default {
  name: 'HomeView',

  components: {
    Hero,
    Benefits,
    Chains,
  },

  data: () => ({
    //
  }),
};
</script>
