<template>
  <v-app>
    <v-app-bar
      app
      absolute
      :color="isLanding ? 'transparent' : '#000'"
      dark
      :src="! isLanding ? '/bg-app-bar.jpg' : null"
      :flat="isLanding"
    >
      
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          position="50% 10%"
        ></v-img>
      </template>

      <v-container>
        <v-row
          justify="space-between"
        >
          <v-col
            cols="6"
            md="4"
            sm="6"
            class="text-right"
          >
            <router-link to="/">
            <div class="d-flex justify-start ">
              <v-img
                src="/logo-white-text.svg"
                contain
                max-height="40"
                position="center left"
              ></v-img>
            </div>
            </router-link>
          </v-col>
          <v-col
            cols="6"
            lg="3"
            md="4"
            align-self="center"
            class="text-right"
          >
            <v-autocomplete
              v-if="!isLanding && !service.always"
              :items="chains"
              v-model="chain"
              auto-select-first
              outlined
              dense
              hide-details
              hide-no-data
              @change="switchChain"
              :disabled="service.always"
              filled
              dark
              placeholder="Select Blockchain"
            >
              <template v-slot:prepend-inner v-if="chain >= 0">
                <v-avatar tile size="24" class="mr-1">
                  <v-img
                    contain
                    :src="'/img/icons/' + getSrc(chain, 'light')">
                  </v-img>
                </v-avatar>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar size="28" tile>
                  <v-img
                    contain
                    :src="'/img/icons/' + getSrc(data.item.value, 'dark')">
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: capitalize;" :dense="false" v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.chain_id"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-menu
              v-if="isLanding"
              offset-y
              closeOnClick
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                  text
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Services
                </v-btn>
              </template>

              <v-list>
                <v-list-item 
                  v-for="(service, index) of services"
                  :key="index"
                  :to="service.path">
                  <v-list-item-title>{{ service.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    
    <v-main>
      <v-container v-if="basePath !== '/'" class="pb-0">
        <v-btn
          v-for="(item, index) of services"
          :key="index"
          :disabled="item.disabled"
          :text="!item.active"
          :to="item.active ? basePath : item.path"
          depressed
          
          class="mr-3"
        >
          {{ item.title }}
        </v-btn>
      </v-container>
      <router-view/>
    </v-main>
    
    <v-footer
      color="#101015"
      dark
      class="pt-10"
    >
      <v-container
        class="text-center"
      >
        <p>
          <v-img
            src="/logo-white-text.svg"
            contain
            height="40"
          ></v-img>
        </p>

        <p>
          <router-link
            class="text-body-1"
            to="/imprint"
            style="color: white; text-decoration: none;"
          >Imprint</router-link>

          <span class="px-2 grey--text">|</span>

          <a
            class="text-body-1"
            href="mailto:hello@starsquid.io"
            style="color: white; text-decoration: none;"
          >Contact</a>
        </p>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  metaInfo: {
    title: "Starsquid"
  },

  components: {},

  data: () => ({
    chains: [],
    chain: -1,
    services: [],
    service: {}
  }),
  methods: {
    getSrc(id, shade) {
      let src = this.chains[id].symbol.toLowerCase()
      if (this.chains[id].dual === true) {
        src += "-" + shade
      }
      if (this.chains[id].icon === undefined) {
        src += ".svg"
      } else {
        src += "." + this.chains[id].icon
      }

      return src
    },

    switchChain() {
      let chain = this.chains[this.chain]
      if (!this.service.always) {
        this.$router.push(`${this.basePath}/${chain.name}/${chain.chain_id}`)
      }
    },

    updateDropdown() {
      let chains = []
      let path = "/" + this.$route.path.split("/")[1]

      for (let chain of this.$mainnets) {
        if (! this.isLanding && chain.excludes.indexOf(path) < 0) {
          chain.dual = chain.dual === true ? true : false,
          chains.push(chain)
        }
      }

      let ordered = chains.sort((a, b) => (a.text).localeCompare(b.text))
      for (let index in chains) {
        chains[index].value = index
      }

      this.chains = ordered

      if (this.chainPath !== "") {
        let chains = this.chains.filter((chain) => {
          if (chain.name + "/" + chain.chain_id === this.chainPath) {
            return chain
          }
        })
        if (chains.length > 0) {
          this.chain = chains[0].value
        }
      } else {
        this.chain = -1
      }

      this.updateServices()
    },

    updateServices() {
      let excludes = []
      let chain = null
      if (this.chain >= 0) {
        chain = this.chains[this.chain]
        if (chain.excludes !== undefined) {
          excludes = chain.excludes
        }
        if (excludes === "*") {
          excludes = this.$services.map(item => item.always === true ? "" : item.path)
        }
      }

      // create list of available
      let services = []
      this.$services.forEach((item) => {
        // copy item
        let service = {...item}

        let active = false
        if (service.path.startsWith(this.basePath)) {
          active = true
          this.service = service
        }

        let disabled = true
        if (excludes.indexOf(service.path) < 0) {
          disabled = false
        }

        service["active"] = active
        service["disabled"] = disabled
        
        if (!this.isLanding) {
          if (!service.always && chain != null) {
            service["path"] = `${service.path}/${chain.name}/${chain.chain_id}`
          }
        }
        
        services.push(service)
      })

      this.services = services
    }
  },
  computed: {
    basePath() {
      return "/" + this.$route.path.split("/")[1]
    },

    chainPath() {
      let params = this.$route.params
      if (params.name === undefined || params.id === undefined ) {
        return ""
      } else {
        return `${params.name}/${params.id}`
      }
    },

    isLanding() {
      return this.$route.path === "/"
    },
  },

  mounted() {
    this.updateDropdown()
  },

  watch: {
    basePath() {
      this.updateDropdown()
    },

    chainPath() {
      this.updateDropdown()
    }
  }
};
</script>
