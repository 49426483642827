<template>
  <div class="py-10">
    <h1 class="text-h3 pb-10 font-weight-light">Why Starsquid</h1>
  <v-row
    justify="center"
  >
    <v-col
      cols="12"
      md="4"
      sm="6"
      v-for="(benefit, index) in benefits"
      :key="index"
    >
      <v-sheet
        color="transparent"
        class="text-center"
      >

        <div class="d-flex flex-column pa-4 align-center">
          <v-img
            height="100px"
            width="100px"
            :src="benefit.img + '.svg'"
          >
          </v-img>
          <span class="py-4 text-h6">{{ benefit.title }}</span>
          <span class="text-body-1" style="line-height: 1.6em;">{{ benefit.text }}</span>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
  </div>
</template>

<script>
  export default {
    name: 'Benefits',

    data: () => ({
      benefits: [
        {
          img: "brain",
          title: "Experienced",
          text: "Almost two decades of experience in running professional services and server setups enable us to build a robust infrastructure and react to changing requirements in next to no time."
        },
        {
          img: "server",
          title: "Secure",
          text: "Security and stability of our systems are our highest priority. We therefore apply strict security policies and our service monitoring is watching 24x7."
        },
        {
          img: "worldwide",
          title: "Decentralized",
          text: "For a maximum of availability and redundancy, we spread out our servers over multiple datacenters of different providers in multiple countries."
        }
      ]
    }),
  }
</script>
